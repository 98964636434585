import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
  } from '@chakra-ui/react';
  import { Button, ButtonGroup } from '@chakra-ui/react';

  function BasicUsage({ userInfo, setUserInfo }) {
    const [showModal, setShowModal] = useState(true);
    return (
      <>
        <Modal isOpen={userInfo.taken === true && userInfo.rulesRead === false}>
          <ModalOverlay />
          <ModalContent
          bg="antiquewhite">
            <ModalHeader>Rules of this game</ModalHeader>
            <ModalBody>
               <ul>
                <li>
                Take the numbers from the Mitu/ Shanto’s box and put them into the bricks at the base of the pyramid. 
                </li>
                <li>
                There will be only one number on each brick in the base of the pyramid. 
                </li>
                <li>
                Add or multiply two adjacent numbers and write down the result on the adjacent upper brick.
                </li>
                <li>
                Fill up the bricks of the upper stage and follow the same rule for the next stage. 
                </li>
                <li>
                In this way, reach the top of the pyramid using addition/ multiplication.
                </li>
                <li>
                It should be noted that you can do multiplication only in one stage and addition in the rest of the stages
                </li>
                <li>
                You must do the same operation among all adjacent bricks in one stage and fill up the upper stage bricks.
                </li>
                <li>
                If you can make more number than Onko mama at the top of the pyramid you will be the winner
                </li>
               </ul>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={() => setUserInfo({ ...userInfo, rulesRead: true })}>
                I understand, START!
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }
export default BasicUsage;