import './App.css';
import Information from './Information';
import Rules from './Rules';
import { Center, Square, Circle } from '@chakra-ui/react';
import {
  Button, Box, Grid, GridItem, ChakraProvider, Flex, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Card
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import Keyboard from './Keyboard';
import Cards from './NumberCard';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Overlay } from './Overlay';
import clsx from 'clsx';
import { CongoConfetti } from './CongoConfetti';

function App({ G, ctx, moves, reset }) {
  const [inputValue, setInputValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [winner, setWinner] = useState(null);
  const [draggedNumber, setDraggedNumber] = useState({ number: -1, index: -1 });
  const [userInfo, setUserInfo] = useState({ gender: null, taken: false, rulesRead: false });
  const [charImageSrc, setCharImageSrc] = useState(null);

  useEffect(() => {
    if (ctx.gameover) {
      setShowModal(true);
      setWinner(ctx.gameover.winner);

      fetch(
        // "https://script.google.com/macros/s/AKfycbySIf9_eo9ziteBEQta7x7Zlg0hhblvMkryktJ2RopcCE5usAnC3QBq31lHNEa-CKf7/exec",
        "https://script.google.com/macros/s/AKfycbxtvk6OWxhJMrZ5dOHECS-9EQ99KlXMhJ0pN5BHVaOeuwNIOLWhCSDBPd1_kUHdkCX9/exec",
        {
          method: "POST",
          body: new URLSearchParams({ ...userInfo, type: 'result', player: G.cells[0][15], ongkomama: G.cells[1][15] }),
        }
      )
        .then((res) => res.text())
        .then((json) => {
          console.log(json);
        })
        .catch((error) => {
          console.log(error);
          alert("error");
        });
    }

    if (ctx.currentPlayer === '1') {
      if (G.positions[1] < 6) {
        moves.computerMoveDropping();
      }
      else {
        moves.computerMovePlayCard();
      }
    }

  }, [ctx]);

  useEffect(() => {
    if (userInfo.gender === 'Mitu') {
      setCharImageSrc('/images/mitu.png');
    }
    else if (userInfo.gender === 'Shanto') {
      setCharImageSrc('/images/player1.png');
    }
  }, [userInfo]);

  return (
    <DndProvider backend={HTML5Backend}>
      <ChakraProvider>
        <Information userInfo={userInfo} setUserInfo={setUserInfo} />
        <Rules userInfo={userInfo} setUserInfo={setUserInfo} />

        <Grid
          h='100vh'
          templateRows='repeat(4, 1fr)'
          templateColumns='repeat(9, 1fr)'
          gap={0}
          bgGradient='linear-gradient(60deg, rgb(222, 170, 118), rgb(222, 211, 140), rgb(133, 75, 36))'
        >
          <GridItem
            rowSpan={2}
            colSpan={2}
            display="flex"
            justifyContent="center"

          >
            <Flex flexDirection='column' gap='5' alignItems='center' className='player1-div'>
              <Cards mtValue={50} moves={moves}
                Cards={G.onHand[0]} playerNumber={0} currentPlayer={parseInt(ctx.currentPlayer)} draggedNumber={draggedNumber}
                setDraggedNumber={setDraggedNumber} />

              <Box
                paddingLeft={['70px']}
                className={clsx('player1-play-button', ctx.currentPlayer === '0' && 'animation')} hidden={G.positions[0] >= 6}>
                <img
                  src='/images/arrow-up.png'
                  className="play-button"
                  alt="board"
                />
              </Box>
            </Flex>
          </GridItem>

          <GridItem
            rowSpan={3}
            colSpan={5}
            display="flex"
            justifyContent="center"
          >
            <Flex flexDirection='row' gap='5' alignItems='center' marginTop={'20px'}>
              <img src='/images/just_board.png' className="board" alt="board" />
              <Overlay cells={G.cells} positions={G.positions} moves={moves} draggedNumber={draggedNumber} setDraggedNumber={setDraggedNumber} inputValue={inputValue} currentPlayer={parseInt(ctx.currentPlayer)} />
            </Flex>
          </GridItem>


          <GridItem
            rowSpan={2}
            colSpan={2}
            display="flex"
            justifyContent="center"
          >
            <Flex flexDirection='row' gap='1' alignItems='center' display="flex">
              <img src='/images/onkomama.png' className="player2" alt="player2" />
            </Flex>
          </GridItem>

          <GridItem
            rowSpan={2}
            colSpan={2}
            display="flex"
            justifyContent="center"
          >
            <Flex flexDirection='row' gap='2' alignItems='center' display="flex">
              {charImageSrc && <img src={charImageSrc} className="asset1" alt="" />}
            </Flex>
          </GridItem>

          <GridItem
            rowSpan={2}
            colSpan={2}
            display="flex"
            justifyContent="center"

          >
            <Flex flexDirection='column' gap='120' display='flex' className='player2-div'>
              <Box
                // paddingBottom={['45px', '30px']}
                marginTop={'-110px'}
                className={clsx(ctx.currentPlayer === '1' && 'animation')} hidden={G.positions[1] >= 6}>
                <img
                  src='/images/arrow-down.png'
                  className="play-button"
                  alt="board"
                />
              </Box>
              {/* -50 mtValue */}
              <Cards mtValue={-90} moves={moves} Cards={G.onHand[1]}
                playerNumber={1} currentPlayer={parseInt(ctx.currentPlayer)} draggedNumber={draggedNumber}
                setDraggedNumber={setDraggedNumber} />
            </Flex>
          </GridItem>

          <GridItem
            rowSpan={1}
            colSpan={5}
            display="flex"
            justifyContent="center"
            marginTop={'30px'}
          >
            <Keyboard gunDone={G.gunDone} cells={G.cells} inputValue={inputValue} setInputValue={setInputValue} moves={moves} currentPlayer={parseInt(ctx.currentPlayer)} positions={G.positions} />
          </GridItem>

          <Modal isOpen={showModal} centerContent>
            <ModalOverlay />
            <ModalContent
              bg="white"  // Background color
              color="black"   // Text color
              borderRadius="md" // Border radius
              top="250px"
              left="0px"
              transform="translate(-50%, -50%)"
              border="2px solid red"
            >
              <ModalHeader>{winner === '0' ? 'Hoorray! You Win! :D' : 'You Lose! :('}</ModalHeader>
              <ModalBody>
                Winner is: <b>{winner === '0' ? userInfo.gender : 'Ongkomama'}</b><br/>
                {userInfo.gender}'s Score: {G.cells[0][15]}<br/>
                Ongkomama's Score: {G.cells[1][15]}<br/>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="green" onClick={() => { setShowModal(false); reset(); moves.resetMove(); moves.resetMove(); setUserInfo({ gender: null, taken: false, rulesRead: false }); setCharImageSrc(null); setWinner(null); }}>Play Again</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

        </Grid>

        {winner === '0' && <CongoConfetti />}

      </ChakraProvider>
    </DndProvider>
  );
}

export default App;
