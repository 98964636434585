import { Client } from 'boardgame.io/react';

import Game from './Game';
import App from './App';

const GameApp = Client({
  game: Game,
  board: App,
  debug: false,
});

export default GameApp;
