import React, { useState } from 'react';
import clsx from 'clsx';

import { useDrag } from "react-dnd";

import './number-card.css';
import { Button, Flex, Stack, Box, Card, CardBody } from '@chakra-ui/react';

const PLayerCard = ({ number, moves, playerNumber, currentPlayer, draggedNumber, setDraggedNumber, index }) => {

    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: 'card',
        canDrag: () => {
            console.log(currentPlayer === playerNumber);
            return currentPlayer === playerNumber;
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }), [currentPlayer, playerNumber]);


    if (isDragging) {
        setDraggedNumber(() => ({ number, index }));
    }

    if (number === -1) return null;

    function handleOnClick(){
        setDraggedNumber(() => ({ number, index }));
        // const selectedCard = document.getElementById(`card-${index}`);
        // selectedCard.classList.add('card-animation');
    }

    return (
        <Box onClick = {handleOnClick} 
        className={clsx('card1', draggedNumber.number === number && 'card-animation')} id={`card-${index}`}  style={{ opacity: isDragging ? 0.5 : 1 }} bg="none" justifyContent="center">
            <h5 className='number'>{number}</h5>
        </Box>
    );
};

const NumberCard = ({ mtValue, moves, Cards, playerNumber, currentPlayer, draggedNumber, setDraggedNumber }) => {

    return (
        <Flex flexDirection='column' gap='2' alignItems='center'>
            <Stack spacing='3' direction='row' justifyContent="center" style={{ display: 'flex', flexWrap: 'wrap' }} mt={mtValue}>
                {Cards.map((card, index) => (
                    <PLayerCard key={index} number={card} moves={moves} playerNumber={playerNumber} currentPlayer={currentPlayer} draggedNumber={draggedNumber} setDraggedNumber={setDraggedNumber} index={index} />
                ))}
            </Stack>

        </Flex>
    );
};

export default NumberCard;
