import React from 'react';
import { Box } from '@chakra-ui/react';
import './dragged-cell.css';
import { useDrop } from 'react-dnd';

const DraggedCell = ({ x, y, style, currentPlayer, playerNumber, draggedNumber, setDraggedNumber, value, moves }) => {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: 'card',
      drop: () => {
        moves.droppingNumber(draggedNumber, x, y);
        var draggedCell = document.querySelector('.card0');
        draggedCell.classList.remove('box-animation');
      },
      canDrop: () => {
        return currentPlayer === playerNumber && value === null;
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop()
      })
    }),
    [draggedNumber, currentPlayer, playerNumber, value, moves, x, y]
  );

  const onClickCanDrop = currentPlayer === playerNumber && value === null;

  function hundleOnClick(){

    if(draggedNumber.number === -1 ) return;
    if(value !== null) return;

    moves.droppingNumber(draggedNumber, x, y);
    var draggedCell = document.querySelector('.card0');
    draggedCell.classList.remove('box-animation');

    setDraggedNumber({ number: -1, index: -1 });
  }

  return (
    <Box   onClick = {hundleOnClick} className={`card0 ${onClickCanDrop ? 'box-animation' : ''}`} style={style}>
      <h5 className='number0'>{value}</h5>
    </Box>
  );
};

export default DraggedCell;
