import { cornerLeftPossitions, cornerRightPossitions, mappingIndexOfOperands } from "./Keyboard";

function getOnHandNumbers() {
  let allDigits = Array.from({ length: 10 }, (_, index) => index);

  // Fisher-Yates (Knuth) shuffle algorithm
  for (let i = allDigits.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [allDigits[i], allDigits[j]] = [allDigits[j], allDigits[i]];
  }

  let midpoint = Math.floor(allDigits.length / 2);
  let part1 = allDigits.slice(0, midpoint);
  let part2 = allDigits.slice(midpoint);
  return [part1, part2];
}

const droppingNumber = ({ G, playerID }, draggedNumber, x, y) => {
  console.log("printing: ", draggedNumber);
  G.cells[x][y] = draggedNumber.number;
  G.onHand[playerID][draggedNumber.index] = -1;
  G.positions[playerID]++;
};

const resetMove = ({ G, playerID }) => {
  G.onHand = getOnHandNumbers();
};

function randomValueWithoutMinusOne(arr) {
  let value = -1;
  let randomIndex = -1;

  // Keep generating a random value until it doesn't have the value -1
  while (value === -1) {
    randomIndex = Math.floor(Math.random() * arr.length);
    value = arr[randomIndex];
  }

  return { number: value, index: randomIndex };
}

function randomDroppedWithoutMinusOne(arr) {
  let value = 0;
  let randomIndex = -1;

  // Keep generating a random value until it doesn't have the value -1
  while (value !== null) {
    randomIndex = Math.floor(Math.random() * 5) + 1;
    value = arr[randomIndex];
  }

  return randomIndex;
}

const computerMoveDropping = ({ G, playerID }) => {
  const draggedNumber = randomValueWithoutMinusOne(G.onHand[1]);
  console.log("printing computer move: ", draggedNumber);
  const y = randomDroppedWithoutMinusOne(G.cells[1]);
  G.cells[1][y] = draggedNumber.number;
  G.onHand[playerID][draggedNumber.index] = -1;
  G.positions[playerID]++;
};

const PlayCard = ({ G, playerID }, draggedNumber, x, y, haveMultiplication = false) => {
  console.log("printing: ", draggedNumber);
  G.cells[x][y] = draggedNumber;
  G.positions[playerID]++;
  if (haveMultiplication) G.gunDone[playerID] = y;
  console.log("hello sajjad");
};

// Function to generate a random integer within a range
function getRandomInteger(min, max) {
  // Using Math.floor() to round down to the nearest integer
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const gunKorbe = (gunDone, position) => {
  if (gunDone !== -1) {
    const ind = cornerLeftPossitions.indexOf(gunDone);
    if (cornerLeftPossitions[ind] <= position && position <= cornerRightPossitions[ind]) {
      return true;
    }
    return false;
  }
  if (gunDone === -1 && position === 15) return true;
  if (cornerLeftPossitions.includes(position)) {
    const randVal = getRandomInteger(1, 6);
    console.log("randVal", randVal);
    if (position > 6 && position < 13 && randVal >= 5) return true;
    if (position >= 13 && randVal < 5) return true;
  }
  return false;
};

const computerMovePlayCard = ({ G, playerID }) => {
  const position = G.positions[1];
  let draggedNumber = G.cells[1][mappingIndexOfOperands[position][0]] + G.cells[1][mappingIndexOfOperands[position][1]];
  if (gunKorbe(G.gunDone[1], position)) {
    draggedNumber = G.cells[1][mappingIndexOfOperands[position][0]] * G.cells[1][mappingIndexOfOperands[position][1]];
    if (G.gunDone[1] === -1) G.gunDone[1] = position;
  }
  G.cells[1][position] = draggedNumber;
  G.positions[playerID]++;
};

const Game = {
  setup: () => ({
    positions: [1, 1],
    droppingDone: false,
    cells: Array.from({ length: 2 }, () => Array.from({ length: 16 }, (_, index) => null)),
    onHand: getOnHandNumbers(),
    gunDone: [-1, -1],
  }),

  turn: {
    minMoves: 1,
    maxMoves: 1,
  },

  phases: {
    dropping: {
      moves: { droppingNumber, computerMoveDropping, resetMove },
      start: true,
      endIf: ({ G }) => {
        return G.positions[0] === 6 && G.positions[1] === 6;
      },
      next: 'operation',
    },

    operation: {
      moves: { PlayCard, computerMovePlayCard },
    },
  },

  // moves: {
  //   clickCell: ({ G, playerID }, id) => {
  //     if (G.positions[playerID] + id <= 101) {
  //       G.positions[playerID] += id;
  //     }
  //   },
  //   droppingNumber: ({ G, playerID }, draggedNumber, x,  y) => {
  //     console.log("printing: ", draggedNumber);
  //     G.cells[x][y] = draggedNumber.number;
  //     G.onHand[playerID][draggedNumber.index] = -1;
  //     G.positions[playerID]++;
  //   },
  // },

  endIf: ({ G, ctx }) => {
    if (G.positions[0] === 16 && G.positions[1] === 16) {
      let winner = '0';
      if (G.cells[0][15] < G.cells[1][15]) {
        winner = '1';
      }
      // TODO - draw case missing
      return { winner };
    }
  },
};

export default Game;