import { Box } from '@chakra-ui/react';
import DraggedCell from './DraggedCell';
import NumberedCell from './NumberedCell';

export const Overlay = ({ cells, positions, moves, draggedNumber, setDraggedNumber, inputValue, currentPlayer }) => {

  return (
    <Box
      style={{
        position: 'absolute',
        marginTop: '10px',
        height: '520px',
        width: '520px',
        textAlign: 'center'
      }}
    >
      {/* first line */}
      <Box
        style={{
          marginTop: '5px',
          marginLeft: '168px',
          height: '42px',
          width: '270px',
          display: 'flex',
          gap: '15px',
        }}
      >
        <DraggedCell setDraggedNumber={setDraggedNumber} x={1} y={1} currentPlayer={currentPlayer} playerNumber={1} draggedNumber={draggedNumber} value={cells[1][1]} moves={moves} style={{
          height: '42px',
          width: '42px',

        }} />
        <DraggedCell setDraggedNumber={setDraggedNumber} x={1} y={2} currentPlayer={currentPlayer} playerNumber={1} draggedNumber={draggedNumber} value={cells[1][2]} moves={moves} style={{
          height: '42px',
          width: '42px',

        }} />
        <DraggedCell setDraggedNumber={setDraggedNumber} x={1} y={3} currentPlayer={currentPlayer} playerNumber={1} draggedNumber={draggedNumber} value={cells[1][3]} moves={moves} style={{
          height: '42px',
          width: '42px',

        }} />
        <DraggedCell setDraggedNumber={setDraggedNumber} x={1} y={4} currentPlayer={currentPlayer} playerNumber={1} draggedNumber={draggedNumber} value={cells[1][4]} moves={moves} style={{
          height: '42px',
          width: '42px',

        }} />
        <DraggedCell setDraggedNumber={setDraggedNumber} x={1} y={5} currentPlayer={currentPlayer} playerNumber={1} draggedNumber={draggedNumber} value={cells[1][5]} moves={moves} style={{
          height: '42px',
          width: '42px',

        }} />
      </Box>

      {/* second line */}
      <Box
        style={{
          marginTop: '5px',
          marginLeft: '193px',
          height: '45px',
          width: '220px',


          display: 'flex',
          gap: '13px'
        }}>

        <NumberedCell x={1} y={6} position={positions[1]} moves={moves} currentPlayer={currentPlayer} playerNumber={1} draggedNumber={inputValue} value={cells[1][6]} style={{
          height: '45px',
          width: '45px',

        }} />

        <NumberedCell x={1} y={7} position={positions[1]} moves={moves} currentPlayer={currentPlayer} playerNumber={1} draggedNumber={inputValue} value={cells[1][7]} style={{
          height: '45px',
          width: '45px',

        }} />

        <NumberedCell x={1} y={8} position={positions[1]} moves={moves} currentPlayer={currentPlayer} playerNumber={1} draggedNumber={inputValue} value={cells[1][8]} style={{
          height: '45px',
          width: '45px',

        }} />

        <NumberedCell x={1} y={9} position={positions[1]} moves={moves} currentPlayer={currentPlayer} playerNumber={1} draggedNumber={inputValue} value={cells[1][9]} style={{
          height: '45px',
          width: '45px',

        }} />
      </Box>

      {/* third line */}
      <Box
        style={{
          marginTop: '5px',
          marginLeft: '220px',
          height: '46px',
          width: '165px',


          display: 'flex',
          gap: '13px'
        }}>

        <NumberedCell x={1} y={10} position={positions[1]} moves={moves} currentPlayer={currentPlayer} playerNumber={1} draggedNumber={inputValue} value={cells[1][10]} style={{
          height: '46px',
          width: '46px',

        }} />

        <NumberedCell x={1} y={11} position={positions[1]} moves={moves} currentPlayer={currentPlayer} playerNumber={1} draggedNumber={inputValue} value={cells[1][11]} style={{
          height: '46px',
          width: '46px',

        }} />

        <NumberedCell x={1} y={12} position={positions[1]} moves={moves} currentPlayer={currentPlayer} playerNumber={1} draggedNumber={inputValue} value={cells[1][12]} style={{
          height: '46px',
          width: '46px',

        }} />
      </Box>


      {/* fourth line */}
      <Box
        style={{
          marginTop: '4px',
          marginLeft: '248px',
          height: '49px',
          width: '110px',


          display: 'flex',
          gap: '11px'
        }}>

        <NumberedCell x={1} y={13} position={positions[1]} moves={moves} currentPlayer={currentPlayer} playerNumber={1} draggedNumber={inputValue} value={cells[1][13]} style={{
          height: '49px',
          width: '49px',

        }} />

        <NumberedCell x={1} y={14} position={positions[1]} moves={moves} currentPlayer={currentPlayer} playerNumber={1} draggedNumber={inputValue} value={cells[1][14]} style={{
          height: '49px',
          width: '49px',

        }} />

      </Box>

      {/* fifth line */}
      <Box
        style={{
          marginTop: '4px',
          marginLeft: '278px',
          height: '51px',
          width: '51px',


          display: 'flex',
          gap: '11px'
        }}>


        <NumberedCell x={1} y={15} position={positions[1]} moves={moves} currentPlayer={currentPlayer} playerNumber={1} draggedNumber={inputValue} value={cells[1][15]} style={{
          height: '51px',
          width: '51px',

        }} />

      </Box>


      {/* 1st line */}
      <Box
        style={{
          marginTop: '6px',
          marginLeft: '190px',
          height: '53px',
          width: '51px',


          display: 'flex',
          gap: '11px'
        }}>

        <NumberedCell x={0} y={15} position={positions[0]} moves={moves} currentPlayer={currentPlayer} playerNumber={0} draggedNumber={inputValue} value={cells[0][15]} style={{
          height: '53px',
          width: '51px',

        }} />

      </Box>

      {/* 2nd line */}
      <Box
        style={{
          marginTop: '4px',
          marginLeft: '160px',
          height: '51px',
          width: '110px',


          display: 'flex',
          gap: '11px'
        }}>



        <NumberedCell x={0} y={13} position={positions[0]} moves={moves} currentPlayer={currentPlayer} playerNumber={0} draggedNumber={inputValue} value={cells[0][13]} style={{
          height: '51px',
          width: '50px',

        }} />

        <NumberedCell x={0} y={14} position={positions[0]} moves={moves} currentPlayer={currentPlayer} playerNumber={0} draggedNumber={inputValue} value={cells[0][14]} style={{
          height: '50px',
          width: '50px',

        }} />

      </Box>


      {/* 3rd line */}
      <Box
        style={{
          marginTop: '2px',
          marginLeft: '132px',
          height: '46px',
          width: '165px',


          display: 'flex',
          gap: '13px'
        }}>

        <NumberedCell x={0} y={10} position={positions[0]} moves={moves} currentPlayer={currentPlayer} playerNumber={0} draggedNumber={inputValue} value={cells[0][10]} style={{
          height: '46px',
          width: '46px',

        }} />

        <NumberedCell x={0} y={11} position={positions[0]} moves={moves} currentPlayer={currentPlayer} playerNumber={0} draggedNumber={inputValue} value={cells[0][11]} style={{
          height: '46px',
          width: '46px',

        }} />

        <NumberedCell x={0} y={12} position={positions[0]} moves={moves} currentPlayer={currentPlayer} playerNumber={0} draggedNumber={inputValue} value={cells[0][12]} style={{
          height: '46px',
          width: '46px',

        }} />

      </Box>


      {/* 4th line */}
      <Box
        style={{
          marginTop: '3px',
          marginLeft: '105px',
          height: '45px',
          width: '220px',


          display: 'flex',
          gap: '13px'
        }}>



        <NumberedCell x={0} y={6} position={positions[0]} moves={moves} currentPlayer={currentPlayer} playerNumber={0} draggedNumber={inputValue} value={cells[0][6]} style={{
          height: '45px',
          width: '45px',

        }} />

        <NumberedCell x={0} y={7} position={positions[0]} moves={moves} currentPlayer={currentPlayer} playerNumber={0} draggedNumber={inputValue} value={cells[0][7]} style={{
          height: '45px',
          width: '45px',

        }} />

        <NumberedCell x={0} y={8} position={positions[0]} moves={moves} currentPlayer={currentPlayer} playerNumber={0} draggedNumber={inputValue} value={cells[0][8]} style={{
          height: '45px',
          width: '45px',

        }} />

        <NumberedCell x={0} y={9} position={positions[0]} moves={moves} currentPlayer={currentPlayer} playerNumber={0} draggedNumber={inputValue} value={cells[0][9]} style={{
          height: '45px',
          width: '45px',

        }} />
      </Box>

      {/* 5th line */}
      <Box
        style={{
          marginTop: '5px',
          marginLeft: '80px',
          height: '42px',
          width: '270px',


          display: 'flex',
          gap: '15px'
        }}>

        <DraggedCell setDraggedNumber={setDraggedNumber} x={0} y={1} moves={moves} currentPlayer={currentPlayer} playerNumber={0} draggedNumber={draggedNumber} value={cells[0][1]} style={{
          height: '42px',
          width: '42px',

        }} />
        <DraggedCell setDraggedNumber={setDraggedNumber} x={0} y={2} moves={moves} currentPlayer={currentPlayer} playerNumber={0} draggedNumber={draggedNumber} value={cells[0][2]} style={{
          height: '42px',
          width: '42px',

        }} />
        <DraggedCell setDraggedNumber={setDraggedNumber} x={0} y={3} moves={moves} currentPlayer={currentPlayer} playerNumber={0} draggedNumber={draggedNumber} value={cells[0][3]} style={{
          height: '42px',
          width: '42px',

        }} />
        <DraggedCell setDraggedNumber={setDraggedNumber} x={0} y={4} moves={moves} currentPlayer={currentPlayer} playerNumber={0} draggedNumber={draggedNumber} value={cells[0][4]} style={{
          height: '42px',
          width: '42px',

        }} />
        <DraggedCell setDraggedNumber={setDraggedNumber} x={0} y={5} moves={moves} currentPlayer={currentPlayer} playerNumber={0} draggedNumber={draggedNumber} value={cells[0][5]} style={{
          height: '42px',
          width: '42px',

        }} />

      </Box>

    </Box>
  );
};