import React, { useState } from 'react';
import {
    Input, Flex, Divider, Text, Heading, Box, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, Badge, Stack, Card, CardHeader, CardBody, CardFooter, ButtonGroup, Button, position
} from '@chakra-ui/react'
import './keyboard.css';

export const mappingIndexOfOperands = {
    6: [1, 2],
    7: [2, 3],
    8: [3, 4],
    9: [4, 5],

    10: [6, 7],
    11: [7, 8],
    12: [8, 9],

    13: [10, 11],
    14: [11, 12],

    15: [13, 14],
}

export const cornerLeftPossitions = [6, 10, 13, 15];
export const cornerRightPossitions = [9, 12, 14, 15];

// 0 => not possible multiplication, 1 => possible multiplication, 2 => force multiplication
const checkForMultiplicationOrNot = (gunDone, position) => {
    if (gunDone === -1) {
        console.log(cornerLeftPossitions, cornerLeftPossitions.includes(position));
        if (position === 15) {
            return 2;
        }
        if (cornerLeftPossitions.includes(position)) {
            return 1;
        }
        return 0;
    }
    const ind = cornerLeftPossitions.indexOf(gunDone);
    if (cornerLeftPossitions[ind] <= position && position <= cornerRightPossitions[ind]) {
        return 2;
    }
    return 0;
}

const Keyboard = ({ gunDone, cells, inputValue, setInputValue, moves, currentPlayer, positions }) => {
    // const [inputValue, setInputValue] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [errorMoveMessage, setErrorMoveMessage] = useState("");

    const handleButtonClick = (value) => {
        setInputValue((prevValue) => prevValue + value);
    };

    const handleBackClick = () => {
        setInputValue((prevValue) => prevValue.slice(0, -1));
    };

    const handleOkClick = () => {
        const position = positions[currentPlayer];
        const flag = checkForMultiplicationOrNot(gunDone[currentPlayer], position);
        let expectedAnswer = cells[currentPlayer][mappingIndexOfOperands[position][0]] + cells[currentPlayer][mappingIndexOfOperands[position][1]];
        if (flag === 2) {
            expectedAnswer = cells[currentPlayer][mappingIndexOfOperands[position][0]] * cells[currentPlayer][mappingIndexOfOperands[position][1]];
        }
        console.log("expectedAnswer", expectedAnswer);
        console.log("flag", flag);
        if (expectedAnswer !== parseInt(inputValue)) {
            // alert("wrong operation");
            if (flag === 1) {
                expectedAnswer = cells[currentPlayer][mappingIndexOfOperands[position][0]] * cells[currentPlayer][mappingIndexOfOperands[position][1]];
                console.log("expectedAnswer", expectedAnswer);
                console.log("parseInt(inputValue)", parseInt(inputValue));
                if (expectedAnswer !== parseInt(inputValue)) {
                    setErrorMoveMessage("Wrong Move!!! You can multiply or make summation here.");
                    setShowModal(true);
                    return;
                }
                else {
                    moves.PlayCard(parseInt(inputValue), currentPlayer, positions[currentPlayer], true);
                    setInputValue('');
                    return;
                }
            }
            if (flag === 2) {
                setErrorMoveMessage("Wrong Move!!! You can only multiply here.");
            }
            else {
                setErrorMoveMessage("Wrong Move!!! You can only make summation here.")
            }
            setShowModal(true);
            return;
        }

        moves.PlayCard(parseInt(inputValue), currentPlayer, positions[currentPlayer]);
        setInputValue('');
    };

    const clearInput = () => {
        setInputValue('');
    };

    const buttons = [
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'
    ];

    return (

        <Card opacity={positions[currentPlayer] <= 5 ? 0.20 : 1.00} pointerEvents={positions[currentPlayer] <= 5 ? 'none' : null} h="120px" w="550px" className='keyboard' bg="none" >
            <CardBody>
                <Flex flexDirection='column' gap='2' alignItems='center'>
                    <Input placeholder='Write your answer' value={inputValue} readOnly bg="white" border="none" hidden />
                    <Stack spacing='1' direction='row' justifyContent="center">


                        {buttons.map((buttonValue) => (
                            <Button key={buttonValue} onClick={() => handleButtonClick(buttonValue)}
                                // variant='outline'
                                colorScheme='pink'
                                fontSize='26px'>
                                {buttonValue}
                            </Button>
                        ))}

                    </Stack>
                    <ButtonGroup spacing='2' >

                        <Button onClick={clearInput} colorScheme='orange'>C</Button>
                        <Button onClick={handleBackClick} colorScheme='orange'>BACK</Button>
                        <Button onClick={handleOkClick} colorScheme='orange'>OK</Button>

                    </ButtonGroup>

                    <Modal isOpen={showModal} onClose={() => setShowModal(false)} centerContent>
                        <ModalOverlay />
                        <ModalContent
                            bg="white"  // Background color
                            color="red"   // Text color
                            borderRadius="md" // Border radius
                            top="250px"
                            left="0px"
                            transform="translate(-50%, -50%)"
                            border="2px solid red"
                            
                        >
                            <ModalHeader>Incorrect Result !!!!</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                {errorMoveMessage}
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="green" onClick={() => setShowModal(false)}>Try Again</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Flex>
            </CardBody>
        </Card>
    );
};

export default Keyboard;
