import React from 'react';
import { Box } from '@chakra-ui/react';


const NumberedCell = ({ x, y, style, currentPlayer, playerNumber, draggedNumber, value, moves, position }) => {
  const canFocus = currentPlayer === playerNumber && y === position;

  const getFontSize = () => {
    const val = canFocus ? draggedNumber : value;
    let fontSize = '20px';

    if (val !== null) {
      if (val.toString().length === 3) {
        fontSize = '15px';
      } else if (val.toString().length >= 4) {
        fontSize = '13px';
      }
    }
    return fontSize;
  };

  return (
    <Box style={{ ...style, border: canFocus ? '5px solid red' : style.border }} className={`card0 ${canFocus ? 'box-animation' : ''}`}>
      <h5 className='number0' style={{ alignItems: 'center', fontSize: getFontSize() }}>{canFocus ? draggedNumber : value}</h5>
    </Box>
  );
};

export default NumberedCell;
