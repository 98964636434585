import React, { useState } from "react";
import {
  ChakraProvider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  useDisclosure,
  Select,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { Radio, RadioGroup } from '@chakra-ui/react';
import { useForm } from "react-hook-form";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";

function BasicUsage({ userInfo, setUserInfo }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  function onSubmit(data) {
    console.log(data);
    setIsLoading(true);
    fetch(
      // "https://script.google.com/macros/s/AKfycbySIf9_eo9ziteBEQta7x7Zlg0hhblvMkryktJ2RopcCE5usAnC3QBq31lHNEa-CKf7/exec",
      "https://script.google.com/macros/s/AKfycbxtvk6OWxhJMrZ5dOHECS-9EQ99KlXMhJ0pN5BHVaOeuwNIOLWhCSDBPd1_kUHdkCX9/exec",
      {
        method: "POST",
        body: new URLSearchParams({ ...data, type: 'info' }),
      }
    )
      .then((res) => res.text())
      .then((json) => {
        console.log(json);
        setUserInfo({ ...data, taken: true, rulesRead: false });
        setIsLoading(false);
        reset();
      })
      .catch((error) => {
        console.log(error);
        alert("error");
        setIsLoading(false);
      });
  }

  return (
    <>
      <Modal isOpen={userInfo.taken === false} centerContent>
        <ModalOverlay />
        <ModalContent
          bg="white" // Background color
          color="black" // Text color
          borderRadius="md" // Border radius
          borderColor="black"
          border="2px solid Azure"
          w="600px"
          backgroundPosition="center"
        >
          <ModalHeader fontSize="large">Fill up the given form to play</ModalHeader>
          <ModalBody paddingTop="-5px">
            <form id="new-form" onSubmit={handleSubmit(onSubmit)}>
              <FormControl paddingLeft="5px" isRequired isInvalid={errors.name}>
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  border="2px solid black"
                  {...register("name", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>

              <br />

              <FormControl
                paddingLeft="5px"
                isRequired
                isInvalid={errors.mobile}
              >
                <FormLabel>Mobile No.</FormLabel>
                <Input
                  type="tel"
                  border="2px solid black"
                  {...register("mobile", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.mobile && errors.mobile.message}
                </FormErrorMessage>
              </FormControl>

              <br />

              <FormControl
                paddingLeft="5px"
                isRequired
                isInvalid={errors.class}
              >
                <FormLabel>Class</FormLabel>
                <Input
                  type="number"
                  border="2px solid black"
                  {...register("class", {
                    required: "This is required",
                    // minLength: { value: 4, message: 'Minimum length should be 4' },
                  })}
                />
                <FormErrorMessage>
                  {errors.class && errors.class.message}
                </FormErrorMessage>
              </FormControl>

              <br />

              <FormControl
                paddingLeft="5px"
                isRequired
                isInvalid={errors.gender}
              >
                <FormLabel>Play as</FormLabel>
                <Box borderRadius="4px" >
                  <RadioGroup>
                    <Stack direction="row" spacing={7}>
                      <Radio {...register("gender", { required: "This is required", })} value="Mitu">Mitu</Radio>
                      <Radio {...register("gender", { required: "This is required", })} value="Shanto">Shanto</Radio>
                    </Stack>
                  </RadioGroup>
                </Box>
                <FormErrorMessage>
                  {errors.gender && errors.gender.message}
                </FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>
          <ModalFooter gap="20px" paddingRight="15px">
            <Button
              colorScheme="green"
              type="submit"
              form="new-form"
              width="80px"
            >
              {isLoading ? <Spinner /> : "Submit"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default BasicUsage;
